import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import url from './Url';

class CollectionSingle extends Component {

    state = {
        idcollection: '',
        CollectionName: '',
        CollectionCollectorID: '',
        CollectionCollectorPhoto: '',
        CollectionCollectorName: '',
        CollectionDescription: '',
        CollectionPages: [],
        CollectorCollections: [],
        CollectionIndex: [],
        CollectionMedals: [],
        CollectionItems: [],
        CollectionBibliography: [],
        baseCollectionUrl: '/collection/',
        baseCollectorUrl: '/collector/',
        baseRarityUrl: '/rarity/',
    }

    componentDidMount() {

        const CollectionData = this.props.CollectionData;
        const idcollection = CollectionData.findIndex(x => x.idcollection === parseInt(this.props.match.params.id));
        console.log(idcollection);
        const CollectionName = CollectionData[idcollection].name
        const CollectionDescription = CollectionData[idcollection].description
        const CollectionPages = CollectionData[idcollection].pages
        const CollectionIndex = CollectionData[idcollection].index
        const CollectionMedals = CollectionData[idcollection].medals
        const CollectionItems = CollectionData[idcollection].items
        const CollectionBibliography = CollectionData[idcollection].bibliography

        document.title = `${CollectionName} - Museum of Philately`

        if(this.props.SubsiteSetting.id){
            document.title = `${CollectionName} - ${this.props.SubsiteSetting.title} - Museum of Philately`
            this.setState({
                baseCollectionUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collection/',
                baseCollectorUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/collector/',
                baseRarityUrl: '/subsites/'+this.props.SubsiteSetting.slug+'/rarity/'
            });
          }





        const CollectionCollectorID = CollectionData[idcollection].collector.id
        const CollectionCollectorPhoto = CollectionData[idcollection].collector.photo.medium
        const CollectionCollectorName = CollectionData[idcollection].collector.name

        this.setState({ idcollection, CollectionItems, CollectionMedals, CollectionIndex, CollectionName, CollectionDescription, CollectionPages, CollectionCollectorID, CollectionCollectorPhoto, CollectionCollectorName, CollectionBibliography });
    }

    Medals() {

        if (this.state.CollectionMedals.length > 0) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Medals</h3>
                        {this.state.CollectionMedals.map((collectionMedals, index) =>
                            <div key={index} className="row border-bottom py-3">
                                <div className="col" key={collectionMedals.idmedals}>
                                    <p className="text-secondary mb-0">{collectionMedals.name}</p>
                                    <p className="text-primary mb-0">{collectionMedals.type}</p>
                                    <p className="text-info mb-0">{collectionMedals.show} / {collectionMedals.year}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }
        return null;

    }

    Highlights() {

        if (this.state.CollectionItems.length > 0) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Highlighted Items</h3>
                        {this.state.CollectionItems.map((collectionItems) =>
                            <div className="row align-items-center py-3 border-bottom" key={collectionItems.id}>
                                <div className="col-3">
                                    <Link to={this.state.baseRarityUrl+collectionItems.id}>
                                        <img className="img-fluid" src={url.url + collectionItems.photo_front.file} alt={'Picture of ' + collectionItems.name}>
                                        </img>
                                    </Link>
                                </div>
                                <div className="col-9 text-left">
                                    <Link to={this.state.baseRarityUrl+collectionItems.id}>
                                        <p className="text-primary">{collectionItems.name}</p>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }
        return null;

    }

    Bibliography() {

        if (this.state.CollectionBibliography.length > 0) {
            return (
                <div className="row">
                    <div className="col bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Bibliography</h3>
                        {this.state.CollectionBibliography.map((collectionBibliography) => {
                            if (this.state.CollectionBibliography.link !== "") {
                                return (
                                    <a href={collectionBibliography.link}>
                                        <p className="text-primary mb-0">{collectionBibliography.name}</p>
                                        <p className="text-info mb-0">{collectionBibliography.author}</p>
                                        <p className="text-secondary">{collectionBibliography.remarks}</p>
                                    </a>
                                )
                            }
                            return (
                                <div>
                                    <p className="text-primary mb-0">{collectionBibliography.name}</p>
                                    <p className="text-info mb-0">{collectionBibliography.author}</p>
                                    <p className="text-secondary">{collectionBibliography.remarks}</p>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            )
        }
        return null;

    }

    render() {
        return (
            <div className="container pt-3 pt-md-5 mb-5">
                <div className="row mb-3 mb-md-5">
                    <div className="col text-center">
                        <h2 className="text-primary font-weight-normal">{this.state.CollectionName}</h2>
                        <p className="text-info">({this.state.CollectionPages.length} pages)</p>
                    </div>
                </div>
                <div className="row text-left">
                    <div className="col-md-3 offset-md-0 col-10 offset-1 bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Collector</h3>
                        <Link to={this.state.baseCollectorUrl+this.state.CollectionCollectorID}>
                            <img className="img-fluid mb-3" src={url.url + this.state.CollectionCollectorPhoto} alt={this.state.CollectionCollectorName}>
                            </img>
                            <p className="text-primary">{this.state.CollectionCollectorName}</p>
                        </Link>
                    </div>
                    <div className="col-md-9 offset-md-0 col-10 offset-1 bg-light py-4 px-4 mb-3">
                        <h3 className="h3-small text-secondary font-weight-normal pb-3">Description</h3>
                        <p className="text-secondary">{this.state.CollectionDescription}</p>
                    </div>
                </div>
                <div className="row align-items-top">
                    <div className="col-md-9 offset-md-0 col-10 offset-1 text-left py-4 px-md-5 overflow-auto">
                        <div className="row">

                            {this.state.CollectionPages.map((collectionPages, index) =>
                                <div key={index} className="col-md-2 col-6">
                                    <Link to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${index + 1}`}>
                                        <img id={collectionPages.page_index} key={collectionPages.idpage} className="img-fluid m-0 collectionpreview" src={url.url + collectionPages.photo.thumbnail} alt={collectionPages.name}>
                                        </img>
                                    </Link>
                                    <Link to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${index + 1}`}>
                                        <p className="text-info text-center p-0 mt-0"><small>Page {collectionPages.page_index + 1}</small></p>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-3 offset-md-0 col-10 offset-1 text-left">
                        {this.Medals()}
                        {this.Highlights()}
                        {this.Bibliography()}
                        <div className="row">
                            <div className="col bg-light py-4 px-4 mb-3">
                                <h3 className="h3-small text-secondary font-weight-normal pb-3">Index</h3>
                                {this.state.CollectionIndex.map((collectionIndex) =>
                                    <div className="row align-items-center py-3 border-bottom" key={collectionIndex.idcategory}>
                                        <div className="col">
                                            <Link to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(collectionIndex.pages[0]) + 1}`}>
                                                <p className="text-primary mb-0">{collectionIndex.name}</p>
                                            </Link>
                                            <Link to={`${this.state.baseCollectionUrl}${this.props.match.params.id}/${parseInt(collectionIndex.pages[0]) + 1}`}>
                                                <p className="text-info page-numbers">{collectionIndex.page_text}</p>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CollectionSingle;
